body {
  margin: 0;
  font-family: 'Inter', sans-serif;
}
* {
  box-sizing: border-box;
}
input {
  font-family: 'Inter', sans-serif;
}

.marker {
  padding-bottom: 4px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
